.metadata-content {
  padding: 32px 24px;
  width: 100%;
  position: relative;
}

.stream-meta-close {
  display: none;
  position: absolute;
  top: 20px;
  right: 12px;
}

@media (max-width: 839px) {
  .stream-meta-close {
    display: block;
  }
}

.stream-meta-details {
  display: flex;
  margin-bottom: 24px;
}

.stream-meta-avatar {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

.stream-meta-text {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 13px;
  line-height: 1.3;
  color: var(--color-white);
}

.stream-meta-username {
  font-size: 15px;
}

.stream-meta-state {
  font-size: 13px;
  position: relative;
  padding-left: 16px;
  color: var(--color-silver);
}

.stream-meta-state::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: var(--color-red);
}

.stream-meta-title {
  margin-bottom: 10px;
  font-size: 17px;
  color: var(--color-white);
  border-bottom: 1px solid var(--color-dark-silver);
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.stream-meta-share {
  color: var(--color-near-white);
  font-size: 14px;
}

.stream-meta-sharelink {
  height: 44px;
  background: var(--color-dark-gray);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
  color: var(--color-silver);
  margin-top: 8px;
  position: relative;
}

.stream-meta-url {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  margin-right: 16px;
}

.stream-meta-sharelink svg {
  cursor: pointer;
}

.stream-meta-sharelink button {
  display: flex;
}
