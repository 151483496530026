.player-buttons {
  position: absolute;
  right: 0;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 1rem;
  z-index: 100;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -webkit-transform: translate3d(0, 0, 0);
}

.divider {
  width: 44px;
  height: 2px;
  border: none;
  background: rgba(255, 255, 255, 0.2);
}

.metadata-toggle {
  display: none;
}

@media (max-width: 839px) {
  .metadata-toggle {
    display: flex;
    row-gap: 1rem;
    flex-direction: column;
  }
}
