.button {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: var(--color-mid-gray);
  font-size: 18px;
  color: white;
  opacity: 0.7;
  border: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-button-lock {
  pointer-events: none;
}

@media (hover: hover) {
  .button:hover {
    opacity: 0.9;
    background: var(--color-mid-gray);
  }
}

.button:active {
  opacity: 0.9;
  background: var(--color-mid-gray);
}
