.feed-content {
  width: 100%;
  height: 100%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
