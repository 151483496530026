video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.6;
  object-fit: cover;
  z-index: -1;
}

.player-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.player-video {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: opacity 0.2s;
}

.player-video.underlay {
  opacity: 0.4;
}

.btn-play-pause {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 25;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  pointer-events: none;
}

.btn-play-pause.active {
  pointer-events: auto;
}

.btn-play {
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  background: var(--color-mid-gray);
  opacity: 0.7;
  border: none;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (hover: hover) {
  .btn-play:hover {
    opacity: 0.9;
  }

  .btn-play.underlay:hover {
    opacity: 0.7;
  }
}
